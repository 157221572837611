<template>
  <div class="home" :style="`heigth:${Height}`">
    <div class="maxwidth">
      <div class="minwidth">
        <h1>Background Check Information</h1>
        <h3>Host Family Member Information</h3>
        <!-- <input type="text" v-model="form.First"> -->
        <el-form ref="form" :rules="rules" :model="form" label-width="80px" class="demo-ruleForm">
          <div class="flex-sb">
            <el-form-item label="Host's Name" prop="First">
              <el-input v-model="form.First" placeholder="First" style="width:70%"></el-input>
            </el-form-item>
            <el-form-item label="" prop="Last">
              <el-input v-model="form.Last" placeholder="Last" style="width:70%;margin-top:40px;"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="How many people are over 18 years old living in the current address now ?"
            prop="SelectValue">
            <el-select placeholder="" @change="Listype" v-model="form.SelectValue">
              <el-option v-for="(item, index) in options" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <div class="div" v-for="(item, index) in FormLength" :key="index">
            <div class="message">Member {{ index + 1 }}</div>
            <div class="flex-sb">

              <!-- :prop="FormLength[index].member_first_name"-->
              <!-- :rules="{ required: true, message: '域名不能为空', trigger: 'blur' }" -->
              <el-form-item label="Name" class="xing">
                <el-input v-model="item.member_first_name" placeholder="First"></el-input>
                <div class="text">(Use the registered name on driver's license.)</div>
              </el-form-item>
              <!-- :prop="item.ming -->
              <el-form-item label="">
                <el-input v-model="item.member_last_name" placeholder="Last" style="margin-top: 40px;"></el-input>
                  <!-- style="width: 46%;margin:40px 0 0 -260px;" -->
              </el-form-item>
            </div>
            <div class="flex-sb">
              <!-- :prop="item.radio -->
              <el-form-item label="Gender"  class="xing">
                <el-radio-group v-model="item.gender">
                  <el-radio label="male">Male</el-radio>
                  <el-radio label="female">Female</el-radio>
                </el-radio-group>

              </el-form-item>
              <!-- :prop="item.rl -->
              <el-form-item label="Date of Birth"  class="xing">
                <el-date-picker v-model="item.birth" type="date" placeholder="(mm/dd/yyyy)" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </div>

            <!-- :prop="item.Address1 -->
            <el-form-item label="Address"  style="width: 98%;"  class="xing">
              <el-input v-model="item.address" placeholder="Address Line 1"></el-input>
            </el-form-item>
            <!-- :prop="item.Address2 -->
            <el-form-item label=""  style="width: 98%;" >
              <el-input v-model="item.address_two" placeholder="Address Line 2" ></el-input>
            </el-form-item>

            <div class="flex-sb" style="width:100%">
              <!-- :prop="item.city -->
              <el-form-item label="">
                <el-input v-model="item.city" placeholder="City" style="width:92%"></el-input>
              </el-form-item>
              <!-- :prop="item.state -->
              <el-form-item label="">
                <el-input v-model="item.region" placeholder="State / Province / Region" style="width:92%"></el-input>
              </el-form-item>
              <!-- :prop="item.postal -->
              <el-form-item label="">
                <el-input v-model="item.postcode" placeholder="Postal / Zip Code" style="width:92%"></el-input>
              </el-form-item>
              <!-- :prop="item.country -->
              <el-form-item label="">
                <el-select v-model="item.country" placeholder="Country" style="width:92%" filterable>
                  <el-option :label="item.name" :value="item.id" v-for="(item, index) in Select" :key="index"></el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="flex-sb">
              <!-- :prop="item.phone -->
              <el-form-item label="Phone"  class="xing">
                <el-input v-model="item.phone"></el-input>
              </el-form-item>
              <!-- :prop="item.Email -->
              <el-form-item label="Email"  class="xing">
                <el-input v-model="item.email"></el-input>
              </el-form-item>
            </div>

            <!-- :prop="item.Social -->
            <el-form-item label="Social Security Number (9 Digits)"  class="xing">
              <el-input v-model="item.social_security_number"></el-input>
              <div class="text">Type in without hyphen (-)</div>
            </el-form-item>
          </div>
          <div class="width20"></div>

          <el-form-item>
            <el-button @click="submitForm('ruleForm')" type="primary">submit!</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { District, Shuibiao } from '@/api/home';
export default {
  name: "Home",
  data() {
    return {
      rules: {
        First: { required: true, message: 'First and Last are required.', trigger: 'blur' },

        Last: { required: true, message: 'Last required.', trigger: 'blur' },

        // member_first_name:
        //   { required: true, message: 'First and Last are required.', trigger: 'blur' },

        // member_last_name:
        //   { required: true, message: 'Last required.', trigger: 'blur' },

        // gender:
        //   { required: true, message: 'Gender is required.', trigger: 'change' },

        // birth: [
        //   { required: true, message: 'Date of Birth is required.', trigger: 'blur' },
        // ],
        // SelectValue: [
        //   { required: true, message: 'How many people are over 18 years old living in the current address now? is required.', trigger: 'change' }
        // ],
        // phone: [
        //   { required: true, message: 'Gender is required.', trigger: 'blur' },
        // ],
        // email: [
        //   { required: true, message: 'Email is required.', trigger: 'blur' },
        // ],

        // address: [
        //   { required: true, message: 'Address1 is required.', trigger: 'blur' },
        // ],

        // address_two: [
        //   { required: true, message: 'Address2 is required.', trigger: 'blur' },
        // ],

        // city: [
        //   { required: true, message: 'City, State, Postal / Zip Code and Country are required.', trigger: 'blur' },
        // ],
        // region: [
        //   { required: true, message: 'State, Postal / Zip Code and Country are required.', trigger: 'blur' },
        // ],
        // postcode: [
        //   { required: true, message: 'Postal / Zip Code and Country are required.', trigger: 'blur' },
        // ],
        // country: [
        //   { required: true, message: 'Country is required.', trigger: 'blur' },
        // ],
        // social_security_number: [
        //   { required: true, message: 'Social Security Number (9 Digits) is required.', trigger: 'blur' },
        // ],
      },
      File: "",
      FormLength: [],
      Select: [],
      form: {
        First: "",
        Last: "",
        SelectValue: 0,
      },
      options: [{
        value: 0,
        label: 'None'
      }, {
        value: 1,
        label: 'One'
      }, {
        value: 2,
        label: 'Two'
      }, {
        value: 3,
        label: 'Three'
      }, {
        value: 4,
        label: 'Four'
      }, {
        value: 5,
        label: 'Five'
      }, {
        value: 6,
        label: 'Six'
      }, {
        value: 7,
        label: 'Seven'
      }, {
        value: 8,
        label: 'Eight'
      }, {
        value: 9,
        label: 'Nine'
      }, {
        value: 10,
        label: 'Ten'
      }],
      Height: "",
    }
  },

  mounted() {
    this.Height = document.documentElement.clientHeight;
    this.HandleList()
  },
  methods: {
    HandleList() {
      District().then(res => {
        if (res.code == 200) {
          this.Select = res.data
        }
      });
    },

    Listype() {
      this.FormLength = [];
   
      for (var i = 1; i <= this.form.SelectValue; i++) {
        this.FormLength.push({
          address: "",
          address_two: "",
          member_first_name: "",
          member_last_name: "",
          phone: "",
          email: "",
          social_security_number: "",
          gender: "",
          birth: "",
          city: "",
          region: "",
          postcode: "",
          country: ''
        })
      }
    },
    // 4、
    submitForm() {
      let { First, Last, SelectValue } = this.form;
      this.$refs.form.validate((valid) => {
        if (valid) { 
          Shuibiao({ host_first_name: First, host_last_name: Last, count: SelectValue, members: this.FormLength }).then(res => {
            if (res.code == 200) {
              this.$message({
                message: 'Information submitted successfully',
                type: 'success'
              });
              this.FormLength = [];
              this.form = {
                First: "",
                Last: "",
                SelectValue: 0,
              }
            } else {
              this.$message({
                message: 'Lack of relevant data',
                type: 'error'
              });
              return
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.xing ::v-deep .el-form-item__label::before{
  content: "*";
  color: red;
  margin-right: 6px;
}
.width20 {
  width: 100%;
  height: 20px;
}

.mao {
  width: 100% !important;
}

.flex-sb {
  display: flex;
  justify-content: space-between;
}

.message {
  color: #333;
  font-weight: bold;
  font-size: 20px;
}

html,
body {
  height: 100%;
}

::v-deep .el-form-item__content {
  margin-left: 0px !important;
}

::v-deep .el-form-item__label {
  float: none;
  font-weight: bold;
}

::v-deep .el-input {
  // width: 40%;
  margin-right: 20px;
}

::v-deep .el-form-item {
  width: 48%;
  // display: inline-block;
}

.home {
  width: 100%;
  min-width: 100%;
  min-height: 930px;
  background: rgba(204, 204, 204, 1);
  background-attachment: fixed;
  padding-bottom: 30px;

  .maxwidth {
    width: 1000px;
    background: #fff;
    margin: 0px auto;
    box-shadow: 0 0 10px hsla(0, 0%, 0%, .1), 1px 1px 2px hsla(0, 0%, 0%, .1);
    padding-bottom: 30px;

    // margin-bottom: 30px;
    .minwidth {
      width: 94%;
      margin: 0 auto;
      text-align: left;

      h1 {
        padding: 30px 0 0 0;
        margin: 0px 0 16px 0;

      }

      h3 {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
